import React, { useEffect, useRef, useState } from 'react'
import { Navbar, Nav, Container, Button, Row, Col } from 'react-bootstrap';
import Fireworks from '@fireworks-js/react';
import Aos from 'aos'
import 'aos/dist/aos.css'
import logo from '../assests/srisrilogo.png'
import homevideo from '../assests/Diwali.mp4'
import discount from '../assests/discount.png'
import Carousel from 'react-bootstrap/Carousel';
import gift from '../assests/gift.png';
import saravedi from '../assests/homesaravedi.png'
import Footer from '../pages/Footer';
import './producttable.css';
import image1 from '../assests/1.jpg';
import Bankfooter from './Bankfooter';
import GiftBoxProduct from './GiftBoxProduct';

const GiftBox = () => {

    const [loading, setLoading] = useState(true);
    const [pdfUrl, setPdfUrl] = useState(null);
    console.log("pdfUrl", pdfUrl);
    const fetchDataRetailPdf = async () => {
        try {
            const response = await fetch("https://api.sreehayagreevacrackers.com/pdf/getcurrentpdf?pdf_name=Gift Box", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            console.log("responseData", responseData);
            setLoading(false);
            if (responseData.status === 200) {
                const encodedUrl = encodeURIComponent(responseData.data.pdf_url); // Encode URL
                setPdfUrl(`https://api.sreehayagreevacrackers.com/uploads/pdfs/${encodedUrl}`); // Construct the full URL
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchDataRetailPdf();
    }, []);

    const handleOpenInNewTab = () => {
        if (pdfUrl) {
            window.open(pdfUrl, '_blank');
        }
    };
    const [headData, setHeadData] = useState([]);
    console.log("headData", headData);
    const fetchHead = async () => {
        try {
            const response = await fetch("https://api.sreehayagreevacrackers.com/content/getallcontents", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            console.log("responseDataHead", responseData);
            setLoading(false);
            if (responseData.status === 200) {
                setHeadData(responseData.contents);
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchHead();
    }, []);
    const retailPriceCategory = headData.find(item => item.category_name === 'Gift Box') || {};
    return (
        <div>
            <div className='head-top my-lg-5 my-2'>
                <div className='head-content'>
                    <div class="text-center text-white py-md-5 py-2">
                        <h5 className='mt-3'>
                            <strong className='text-decoration-underline mb-4'>SREE HAYAGREEVA CRACKERS</strong>
                        </h5>
                        <h5 className='mt-4'>{retailPriceCategory.text1}</h5>
                    </div>
                </div>
            </div>

            {/* price list table start*/}
            <div className="container-fluid mt-3">
                <div className='d-flex justify-content-end mb-2'>
                    <Button onClick={handleOpenInNewTab} disabled={loading} className="download-button fs-6">
                        {loading ? 'Loading...' : 'Download Pdf'}
                    </Button>
                </div>
                <div>
                    <GiftBoxProduct />
                </div>
            </div>
            {/* price list table end*/}

            <div>
                <Bankfooter />
                <Footer />
            </div>
        </div>
    )
}

export default GiftBox;