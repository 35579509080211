import React, { useEffect, useRef, useState } from 'react'
import { Navbar, Nav, Container, Button, Row, Col } from 'react-bootstrap';
import Fireworks from '@fireworks-js/react';
import Aos from 'aos'
import 'aos/dist/aos.css'
import bannerlogo from '../assests/output-onlinepngtools.png'
import logo from '../assests/srisrilogo.png'
import homevideo from '../assests/Diwali.mp4'
import discount from '../assests/discount.png'
import gift from '../assests/gift.png';
import saravedi from '../assests/homesaravedi.png'
import Footer from '../pages/Footer';

import './producttable.css';
import image1 from '../assests/1.jpg';
import cover from '../assests/why1.jpg';
import Bankfooter from './Bankfooter';
import FamilyPackProduct from './FamilyPackProduct';
import RetailPriceProduct from './RetailPriceProduct';

import Carousel from 'react-bootstrap/Carousel';



const RetailPrice = () => {

    useEffect(() => {
        Aos.init()
    }, [])
    const fireworksRef = useRef(null)
    const [loading, setLoading] = useState(true);
    const [pdfUrl, setPdfUrl] = useState(null);
    console.log("pdfUrl", pdfUrl);
    const fetchDataRetailPdf = async () => {
        try {
            const response = await fetch("https://api.sreehayagreevacrackers.com/pdf/getcurrentpdf?pdf_name=Retail Price", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            console.log("responseData", responseData);
            setLoading(false);
            if (responseData.status === 200) {
                const encodedUrl = encodeURIComponent(responseData.data.pdf_url); // Encode URL
                setPdfUrl(`https://api.sreehayagreevacrackers.com/uploads/pdfs/${encodedUrl}`); // Construct the full URL
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchDataRetailPdf();
    }, []);

    const handleOpenInNewTab = () => {
        if (pdfUrl) {
            window.open(pdfUrl, '_blank');
        }
    };
    const [headData, setHeadData] = useState([]);
    console.log("headData", headData);
    const fetchHead = async () => {
        try {
            const response = await fetch("https://api.sreehayagreevacrackers.com/content/getallcontents", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            console.log("responseDataHead", responseData);
            setLoading(false);
            if (responseData.status === 200) {
                setHeadData(responseData.contents);
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchHead();
    }, []);
    const retailPriceCategory = headData.find(item => item.category_name === 'Retail Price') || {};
    return (
        <div>
            <div className='videomt mt-0'>
                <Carousel>
                    <Carousel.Item>
                        <div className='video-container'>
                            <video src={homevideo} autoPlay loop muted >
                            </video>
                            <div className='content'>
                                <img src={gift} className='giftimg'></img>
                            </div>
                            <div className='saravedi'>
                                <img src={saravedi} className='saravediimg'></img>
                            </div>
                            {/* <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                                <Fireworks ref={fireworksRef}
                                />
                            </div> */}
                            <div className='homebannerdiscount mt-3'>
                                <img src={discount} className='homediscount'></img>
                            </div>
                            <div className='videos-center'>
                                <div>
                                    <img
                                        src={logo}
                                        alt="Sri Srinivasa Crackers"
                                        className='home-logo-video'
                                    />
                                </div>
                                <div>
                                    <h5 className='video-welcome'> WelCome To</h5>
                                </div>
                                <div>
                                    <h2 className='video-company-name'>SREE HAYAGREEVA CRACKERS</h2>
                                </div>
                                <div>
                                    <p className='video-para'>
                                        Sree Hayagreeva Crackers is a top-tier Manufacturer , Wholesale and Retail <br></br>supplier of exceptional quality fireworks, providing its products to <br></br>customers across various regions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div >

            <div className='head-top my-lg-5 my-2'>
                <div className='head-content'>
                    <div class="text-center text-white">
                        <h2 className='pt-4'>Sree Hayagreeva<strong>Crackers&nbsp;</strong></h2>
                        <h3 className='mt-3'>Crackers Available 365 Days. Order now And Get Delivery immediately.</h3>
                        <p className='mt-4'>
                            <strong>{retailPriceCategory.text1}
                            </strong>
                        </p>
                        <p className='mt-5'>
                            <strong>{retailPriceCategory.text2}</strong>
                        </p>
                        <p className='mt-5'>The Product Image is only for your reference, the packing and brand may change</p>
                        <p className='mt-4 head-content-last pb-5'>
                            <strong className=''>Crackers In Sivakasi,Best Crackers Sivakasi,Crackers in India</strong>
                        </p>
                    </div>
                </div>
            </div>

            {/* price list table start*/}

            <div className="container-fluid mt-3">
                <div className='d-flex justify-content-end mb-2'>
                    <Button onClick={handleOpenInNewTab} disabled={loading} className="download-button fs-6">
                        {loading ? 'Loading...' : 'Download Pdf'}
                    </Button>
                </div>
                <div>
                    <RetailPriceProduct />
                </div>
            </div>

            {/* price list table end*/}
            <div>
                <Bankfooter />
                <Footer />
            </div>
        </div>
    )
}

export default RetailPrice